export let itemComponents = [
  {
    id: "frame_comp",
    title: "Frame",
    top: null,
    right: null,
    bottom: null,
    left: null,
    width: 100,
    height: 100,
    svg: "",
  },
  {
    id: "image_logo_comp",
    title: "Image Logo",
    top: null,
    right: null,
    bottom: null,
    left: null,
    width: 100,
    height: 100,
    groupColor: 0,
    src: "https://firebasestorage.googleapis.com/v0/b/pictsnap-next.appspot.com/o/example.jpg?alt=media&token=06cf52ea-0783-4f2a-a10b-f244ae6ce411",
    imageEditingController: 0,
  },
  {
    id: "image_square_comp",
    title: "Image Square",
    top: null,
    right: null,
    bottom: null,
    left: null,
    width: 100,
    height: 100,
    groupColor: 0,
    border: 0,
    src: "https://firebasestorage.googleapis.com/v0/b/pictsnap-next.appspot.com/o/example.jpg?alt=media&token=06cf52ea-0783-4f2a-a10b-f244ae6ce411",
    imageEditingController: 0,
  },
  {
    id: "image_square_rounded_comp",
    title: "Image Square Rounded",
    top: null,
    right: null,
    bottom: null,
    left: null,
    width: 100,
    height: 100,
    groupColor: 0,
    rounded: 10,
    border: 0,
    src: "https://firebasestorage.googleapis.com/v0/b/pictsnap-next.appspot.com/o/example.jpg?alt=media&token=06cf52ea-0783-4f2a-a10b-f244ae6ce411",
    imageEditingController: 0,
  },
  {
    id: "image_circle_comp",
    title: "Image Circle",
    top: null,
    right: null,
    bottom: null,
    left: null,
    width: 100,
    height: 100,
    groupColor: 0,
    border: 0,
    src: "https://firebasestorage.googleapis.com/v0/b/pictsnap-next.appspot.com/o/example.jpg?alt=media&token=06cf52ea-0783-4f2a-a10b-f244ae6ce411",
    imageEditingController: 0,
  },
  {
    id: "image_static_comp",
    title: "Image Static",
    top: null,
    right: null,
    bottom: null,
    left: null,
    width: 800,
    height: 100,
    src: "",
  },
  {
    id: "container_square_comp",
    title: "Container Square",
    top: null,
    right: null,
    bottom: null,
    left: null,
    width: 100,
    height: 100,
    groupColor: 0,
    border: 0,
  },
  {
    id: "container_square_rounded_comp",
    title: "Container Square Rounded",
    top: null,
    right: null,
    bottom: null,
    left: null,
    width: 100,
    height: 100,
    groupColor: 0,
    border: 0,
    rounded: 10,
  },
  {
    id: "container_circle_comp",
    title: "Container Circle",
    top: null,
    right: null,
    bottom: null,
    left: null,
    width: 100,
    height: 100,
    groupColor: 0,
    border: 0,
  },
  {
    id: "text_comp",
    title: "Text",
    top: null,
    right: null,
    bottom: null,
    left: null,
    text: "",
    fontSize: 12,
    fontFamily: "Poppins",
    fontWeight: "bold",
    textAlign: "center",
    textController: 0,
    groupColor: 0,
    width: 0,
    height: 0,
  },
];
